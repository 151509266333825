.main {
  height: 100%;
  width: 100%;
  margin: 0px;
  padding: 25px;
  overflow-y: auto;
  overflow-x: auto;
}

.heading {
  font-size: 20px;
  font-weight: 400;
  margin: 0px;
}

.topbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
}

.tabsholder {
  width: 83vw;
  overflow-x: scroll;
}

.addbuttongreen {
  background: rgb(67, 161, 67);
  padding: 5px 20px;
  width: fit-content;
  height: fit-content;
  font-size: 16px;
  color: white;
  font-weight: bold;
  border-radius: 5px;
  border: none;
  margin: 0px;
}

.addbutton {
  background: var(--color-button-background);
  padding: 5px 20px;
  width: fit-content;
  height: fit-content;
  font-size: 14px;
  font-weight: bold;
  color: white;
  font-weight: 300;
  border-radius: 5px;
  border: none;
  margin: 0px;
}
.addbutton:disabled {
  background: grey;
  padding: 5px 20px;
  width: fit-content;
  height: fit-content;
  font-size: 14px;
  font-weight: bold;
  color: white;
  font-weight: 300;
  border-radius: 5px;
  border: none;
  margin: 0px;
  cursor: not-allowed;
}

.addbuttondisabled {
  background: grey;
  padding: 5px 20px;
  width: fit-content;
  height: fit-content;
  font-size: 14px;
  font-weight: bold;
  color: white;
  font-weight: 300;
  border-radius: 5px;
  border: none;
  margin: 0px;
  cursor: not-allowed;
}

.addbutton:hover {
  cursor: pointer;
  transform: scale(1.05);
}

.addbuttongreen:hover {
  cursor: pointer;
  transform: scale(1.05);
}

.icons {
  border-radius: 5px;
}

.icons:hover {
  cursor: pointer;
  transform: scale(1.05);
}

.disicons:hover {
  cursor: not-allowed;
}

.buttonholder {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 30px;
}

.disabledbutton {
  background: grey;
  padding: 5px 20px;
  width: fit-content;
  height: fit-content;
  font-size: 14px;
  color: white;
  font-weight: 300px;
  border-radius: 5px;
  border: none;
  margin: 0px;
}

.disabledbutton:hover {
  cursor: not-allowed;
  transform: scale(1.05);
}

.badgeonline {
  text-transform: uppercase;
  color: var(--color-button-background);
  font-size: 10px;
  font-weight: 300;
  padding: 5px;
  border-radius: 5px;
  z-index: 30;
  position: absolute;
  top: 0px;
  right: 8px;
  background-color: white;
}

.badgeoffline {
  text-transform: uppercase;
  color: #1976d2;
  font-weight: 300;
  font-size: 10px;
  padding: 5px;
  border-radius: 5px;
  z-index: 30;
  position: absolute;
  top: 0px;
  right: 8px;
  background-color: white;
}

.badgehybrid {
  text-transform: uppercase;
  font-weight: 300;
  font-size: 10px;
  padding: 5px;
  border-radius: 5px;
  z-index: 30;
  position: absolute;
  top: 0px;
  right: 8px;
  background-color: white;
}

.searchholder {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 250px;
  border: 1px solid black;
  border-radius: 5px;
  padding: 5px 10px;
}

.searchinput {
  border: none;
  height: 30px;
  font-size: 14px;
  width: 200px;
  outline: none;
  background: transparent;
}

.hidden {
  display: none;
}
.hoverCell:hover .hidden {
  display: inline;
}
